import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/layout'
import ArticleSummary from 'components/article-summary'

const Blog = ({ data, location }) => {

  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location}>
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }


  return (
    <Layout location={location}>

      <section id='articles'>
        <div className='global-wrapper'>
          {posts.map(post => {
            console.log(location)
            const title = post.frontmatter.title || post.frontmatter.slug
            switch (post.frontmatter.template) {
              case "article":
                
                return (
                  <ArticleSummary 
                    key={post.frontmatter.slug}
                    title={title}
                    date={post.frontmatter.date}
                    slug={post.frontmatter.slug}
                    author='1'
                    role='1'
                    category={post.frontmatter.category}
                    tags={post.frontmatter.tags}
                    post={post}
                  />
                )
              case "changelog":
                return (
                  <h5 key={title}>changelog</h5>
                )
              case "product":
                return (
                  <h5 key={title}>product</h5>
                )
              case "announcement":
                return (
                  <h5 key={title}>member</h5>
                )
              default:
                return (
                  <h2 key={title}>bye</h2>
                )
            }
          })}
        </div>
      </section>
    </Layout>
  )
}

export default Blog


export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt(format: HTML)
        html
        frontmatter {
          slug
          date(formatString: "MMMM DD, YYYY")
          title
          template
          tags
          category
        }
      }
    }
  }
`