import React from 'react'
import { Link } from 'gatsby'

const ArticleSummary = ({ 
  title,
  author,
  role,
  category,
  slug,
  date,
  tags, 
  post
}) => (
  <article className='article-summary' itemScope itemType="http://schema.org/Article">
    <h6>{category}</h6>
    <h1>
      <Link to={slug}  itemProp="url">{title}</Link>
    </h1>

    <div className='date'>
      <p><b>Publicado</b> {date}</p>
      <p className='lecture-time'><b>Tiempo de lectura:</b> 3 minutos</p>
    </div>

    <div className='author'>
      <img src='#' alt='autor'/>
      <div className='author-data'>
        <p className='name'>{author}</p>
        <p className='role'>{role}</p>
      </div>
    </div>

    <section className='content'
      dangerouslySetInnerHTML={{ __html: post.excerpt }}
      itemProp="articleBody"
    />

    <footer>
      <Link to={slug}>Leer mas</Link>
      <div>
        {tags.map(tag => {
          return (
            <span>{tag}</span>
          )
        })}
      </div>
    </footer>
  </article>
)

export default ArticleSummary